import React from "react"
import Banner from "./banner"
import AffiliateLeadForm from "./affiliateLeadForm"
import "./contactUs.css"

const ContactUs = () => {
  return (
    <div className="contact-us">
      <Banner />
      <div className="contact-us-body">
        <div className="contact-us-body-header">
          <h1>
            Contact <strong>Compare n Save</strong>
          </h1>
          <p>
            Whether you want to refinance or are starting from scratch, we’re
            here to help you. Contact our expert team today.
          </p>
        </div>

        <div className="contact-us-form">
          <div className="contact-us-form-header">
            Fill in your details and we’ll get in touch.
          </div>
          <AffiliateLeadForm />
        </div>

        {/* <CardGroup>
          <Card>
            <Card.Body>
              <Card.Title>
                Stay across the
                <br />
                <strong>
                  latest news
                  <br />
                  and updates
                </strong>
              </Card.Title>
              <div className="contact-us-social-media">
                <a
                  href="https://www.facebook.com/profile.php?id=100063955214155"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook className="icon" />
                </a>
                <a
                  href="https://www.instagram.com/comparensave.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram className="icon" />
                </a>
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>
                Quickly compare
                <br />
                <strong>
                  hundreds of
                  <br />
                  home loans
                </strong>
              </Card.Title>
              <Button
                variant="secondary"
                className="contact-us-button"
                onClick={() => {
                  dispatch({ type: "SET_REFINANCE", refinance: false })
                  navigate("/product")
                }}
              >
                Find a loan
              </Button>
            </Card.Body>
          </Card>
        </CardGroup> */}
      </div>
    </div>
  )
}

export default ContactUs
